// デバッグ用の const 文字列

import { DiscInfo } from '../components/commons/music-disc/music-disc-interface';

export type MusicList = DiscInfo[];

export const musicList: MusicList = [
  [
    {
      meta: {
        title: 'Fast Lane',
        artist: 'NIVIRO Ft. Polly Anna',
        musicId: 'm1',
        bpm: 128.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm1/1.jpg',
      },
      scores: [[], []],
    },
    {
      meta: {
        title: 'I Just Wanna',
        artist: 'NIVIRO',
        musicId: 'r1',
        bpm: 128.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm1/2.jpg',
      },
      scores: [[], []],
    },
  ],
  [
    {
      meta: {
        title: 'Seasons (feat. Harley Bird) [Futuristik & Whogaux Remix]',
        artist: 'Rival x Cadmium',
        musicId: 'm2',
        bpm: 130.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm2/1.jpg',
      },
      scores: [[], []],
    },
    {
      meta: {
        title: 'Seasons (feat. Harley Bird) [NIVIRO Remix]',
        artist: 'Rival & Cadmium',
        musicId: 'r2',
        bpm: 130.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm2/2.jpg',
      },
      scores: [[], []],
    },
  ],
  [
    {
      meta: {
        title: 'Link',
        artist: 'Jim Yosef',
        musicId: 'm3',
        bpm: 100.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm3/1.jpg',
      },
      scores: [[], []],
    },
    {
      meta: {
        title: 'Linked',
        artist: 'Jim Yosef & Anna Yvette',
        musicId: 'r3',
        bpm: 100.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm3/2.jpg',
      },
      scores: [[], []],
    },
  ],
  [
    {
      meta: {
        title: 'Nekozilla',
        artist: 'Different Heaven',
        musicId: 'm4',
        bpm: 128.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm4/1.jpg',
      },
      scores: [[], []],
    },
    {
      meta: {
        title: 'Nekozilla (LFZ Remix)',
        artist: 'Different Heaven',
        musicId: 'r4',
        bpm: 118.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm4/2.jpg',
      },
      scores: [[], []],
    },
  ],
  [
    {
      meta: {
        title: 'Make Me Move (feat. Karra)',
        artist: 'Culture Code',
        musicId: 'm5',
        bpm: 95.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm5/1.jpg',
      },
      scores: [[], []],
    },
    {
      meta: {
        title: 'Make Me Move (feat. Karra) [Tobu Remix]',
        artist: 'Culture Code',
        musicId: 'r5',
        bpm: 100.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm5/2.jpg',
      },
      scores: [[], []],
    },
  ],
  [
    {
      meta: {
        title: 'My Heart',
        artist: 'Different Heaven & EH!DE',
        musicId: 'm6',
        bpm: 87.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm6/1.jpg',
      },
      scores: [[], []],
    },
    {
      meta: {
        title: 'Infectious',
        artist: 'Tobu',
        musicId: 'r6',
        bpm: 128.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm6/2.jpg',
      },
      scores: [[], []],
    },
  ],
  [
    {
      meta: {
        title: 'Runaway (feat. Valentina Franco)',
        artist: 'Halcyon',
        musicId: 'm7',
        bpm: 150.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm7/1.jpg',
      },
      scores: [[], []],
    },
    {
      meta: {
        title: 'Runaway (feat. Valentina Franco) [TARI Remix]',
        artist: 'Halcyon',
        musicId: 'r7',
        bpm: 160.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm7/2.jpg',
      },
      scores: [[], []],
    },
  ],
  [
    {
      meta: {
        title: 'I Know',
        artist: '3rd Prototype',
        musicId: 'm8',
        bpm: 128.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm8/1.jpg',
      },
      scores: [[], []],
    },
    {
      meta: {
        title: 'Feel So Good',
        artist: '3rd Prototype',
        musicId: 'r8',
        bpm: 160.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm8/2.jpg',
      },
      scores: [[], []],
    },
  ],
  [
    {
      meta: {
        title: 'Popsicle',
        artist: 'LFZ',
        musicId: 'm9',
        bpm: 128.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm9/1.jpg',
      },
      scores: [[], []],
    },
    {
      meta: {
        title: 'Skyline',
        artist: 'Kovan & Electro Light',
        musicId: 'r9',
        bpm: 128.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm9/2.jpg',
      },
      scores: [[], []],
    },
  ],
  [
    {
      meta: {
        title: 'Animal (ft. Jex)',
        artist: 'Debris & RudeLies',
        musicId: 'm10',
        bpm: 126.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm10/1.jpg',
      },
      scores: [[], []],
    },
    {
      meta: {
        title: 'Eclipse',
        artist: 'Jim Yosef',
        musicId: 'r10',
        bpm: 125.0,
        offsetTime: 0.0,
        level: 5,
        discImage: 'm10/2.jpg',
      },
      scores: [[], []],
    },
  ],
];
