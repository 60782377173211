export const MENU_MUSIC_SELECT = 'MENU_MUSIC_SELECT';
export const MENU_DJ_MODE = 'MENU_DJ_MODE';
export const MENU_OPTIONS = 'MENU_OPTIONS';
export const DISC_LABEL = 'DISC_LABEL';
export const FADER_BAR = 'FADER_BAR';
export const MUSIC_SELECT_PLAY_BUTTON = 'MUSIC_SELECT_PLAY_BUTTON';
export const MUSIC_SELECT_CONFIRM = 'MUSIC_SELECT_CONFIRM';
export const MUSIC_SELECT_CONFIRM_CANCEL = 'MUSIC_SELECT_CONFIRM_CANCEL';
export const MUSIC_SELECT_BACK_BUTTON = 'MUSIC_SELECT_BACK_BUTTON';
export const MUSIC_SELECT_MIDI_BUTTON = 'MUSIC_SELECT_MIDI_BUTTON';
export const MUSIC_SELECT_SHARE_BUTTON = 'MUSIC_SELECT_SHARE_BUTTON';
export const MUSIC_SELECT_SHARE_CLOSE_BUTTON =
  'MUSIC_SELECT_SHARE_CLOSE_BUTTON';
export const MUSIC_SELECT_SHARE_TWITTER = 'MUSIC_SELECT_SHARE_TWITTER';
export const MUSIC_SELECT_SHARE_FACEBOOK = 'MUSIC_SELECT_SHARE_FACEBOOK';
export const MUSIC_SELECT_SHARE_LINE = 'MUSIC_SELECT_SHARE_LINE';
export const KNOB_PLATE = 'KNOB_PLATE';
export const KNOB_COMPONENT_TARGET = 'KNOB_COMPONENT_TARGET';
export const KAOSS_PAD = 'KAOSS_PAD';
export const BACK_TO_DJ_MODE = 'BACK_TO_DJ_MODE';
export const ARRIVAL_CONTAINER = 'ARRIVAL_CONTAINER';
export const CREDIT_BUTTON = 'CREDIT_BUTTON';
export const GITHUB_BUTTON = 'GITHUB_BUTTON';
export const CREDIT_CONTAINER = 'CREDIT_CONTAINER';
export const NCS_LINK = 'NCS_LINK';
export const LEONARDO_LINK = 'LEONARDO_LINK';
export const TUTORIAL_NEXT_PAGE = 'TUTORIAL_NEXT_PAGE';
export const TUTORIAL_BACK_PAGE = 'TUTORIAL_BACK_PAGE';
export const TUTORIAL_CLOSE = 'TUTORIAL_CLOSE';
export const TUTORIAL_SKIP_NEXT = 'TUTORIAL_SKIP_NEXT';
export const TUTORIAL_BODY = 'TUTORIAL_BODY';
export const PLAYER_START_MUSIC = 'PLAYER_START_MUSIC';
export const TUTORIAL_MS_CLOSE = 'TUTORIAL_MS_CLOSE';
export const TUTORIAL_MS_SKIP_NEXT = 'TUTORIAL_MS_SKIP_NEXT';
